*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Mint type vinmonopro", sans-serif;

  /* border: 1px solid #fff */
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

a{
  color: inherit !important;
  text-decoration: none !important;
}

a:hover,button:hover{
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #FFFFFF;
}

h1{
  font-size:2rem;
}

a,input{
  font-size:1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  padding: 0;
  margin: 0;
  object-fit: cover;
}

input{
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0) inset !important;
    -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}


.ac 
.carousel-slider {
  height: auto !important;
}
.carousel-slider {
  height: 33.75vw;
}

@media only screen and (max-width: 480px) {
  .carousel-slider {
    height: 70vw;
  }

}