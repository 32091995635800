.controller{
    /* max-height: 120px; */
    height: 22.5vh;
    z-index: 100;
    margin-bottom: 2.5vh;
}

.controller:hover{
    cursor: pointer;
}


.controllercontainer{
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.controllerPosition{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button{
    position: absolute;
    width: 90px;
    height: 32px;
    transition: opacity 1s;
    z-index: 110;
    justify-content: center;
    align-items: center;
}

.button1{
    bottom: 25vh;
}

.button2{
    right: 25vh;
    bottom: 15vh;
}

.button3{
    right: 25vh;
    bottom: 5vh;
}

.button4{
    left: 25vh;
    bottom: 15vh;
}

.button5{
    left: 25vh;
    bottom: 5vh;
}

.button6{
    bottom: 25vh;
}

.button7{
    position: absolute;
    transition: opacity 1s;
    z-index: 110;
    justify-content: center;
    align-items: center;
    bottom: 40vh;
}
.overlay{
    position: fixed;
    top: 0px;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}


.zero{
    opacity: 0;
    display: none;
}

.max{
    opacity: 1;
    display: flex;
}

@media only screen and (max-width: 900px){
    .button7{
        bottom: 50vh;
    }
    .button1{
        bottom: 27.5vh;
    }
    
    .button2{
        right: 25vh;
        bottom: 17.5vh;
    }
    
    .button3{
        right: 25vh;
        bottom: 5vh;
    }
    
    .button4{
        left: 25vh;
        bottom: 17.5vh;
    }
    
    .button5{
        left: 25vh;
        bottom: 5vh;
    }
    
    .button6{
        bottom: 30vh;
    }
}

@media only screen and (max-width: 480px) and (orientation: portrait) {
    .controller{
        height: 20vh;
    }
    .button1{
        bottom: 20vh;
    }
    
    .button2{
        left: 50%;
        transform: translateX(-50%);
        bottom: 30vh;
    }
    
    .button3{
        left: 50%;
        transform: translateX(-50%);
        bottom: 40vh;
    }
    
    .button4{
        left: 50%;
        transform: translateX(-50%);
        bottom: 50vh;
    }
    
    .button5{
        left: 50%;
        transform: translateX(-50%);
        bottom: 60vh;
    }

    .button6{
        bottom: 70vh;
    }

    .button7{
        bottom: 80vh;
    }
}