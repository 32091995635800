.signup{
	display: flex;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	align-items: center;
}

.container{
    display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: flex-start;
	width: 25vw;
    max-height: 50vh;
    background-color: rgba(0, 0, 0, 0.8);
    border: 2px solid #00FEFB;
	padding: 4vh 2.5vh;
	padding-bottom: 2vh;
	overflow-y: scroll;
	border-radius: 10px
}

.input{
    background: transparent;
    border: none;
    outline: none;
    width: 20vw;
    height: 40px;
    color: #fff;
    border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
    padding: 0vh 10px;
	border-radius: 7.5px;
	margin: 0.5vw;
}

.input input{
    background: transparent;
    border: none;
    outline: none;
    width: 20vw;
    color: #fff;
    padding: 0vh 10px;
	border-radius: 7.5px;
	margin-bottom: 0px !important;
}

.login{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login input{
	margin-bottom: 20px;
}

.btn{
	margin-bottom: 10px;
}

.row{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
}

.column{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.btnMob{
	display: block;
}

.container{
	display: none;
}

@media only screen and (max-width: 480px) {
	.btnMob{
		display: block;
	}

	.container{
		display: none;
	}
}