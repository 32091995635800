.background-video{
    position: fixed;
    z-index:-1;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgba(55, 139, 127, 1); /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 10px;
  margin-bottom: 12.5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-Bold.otf) format('opentype'), local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-ExtraBold.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-BoldItalic.otf) format('opentype'), local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-ExtraBoldItalic.otf) format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-ExtraLight.otf) format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-SemiBoldItalic.otf) format('opentype');
    font-weight: 600;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-Light.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-LightItalic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-Italic.otf) format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-ExtraLightItalic.otf) format('opentype');
    font-weight: 200;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-MediumItalic.otf) format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-Medium.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-Regular.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-SemiBold.otf) format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-Thin.otf) format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Mint type vinmonopro';
    src: local('Mint type vinmonopro'), url(./fonts/Mint-Type---VinMonoPro-ThinItalic.otf) format('opentype');
    font-weight: 100;
    font-style: italic;
    font-display: auto;
  }