.button{
    background-image: url(../../assets/img/flyout/flyoutv6-p-500.png);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 1.1rem;
    font-family: "Mint type vinmonopro", sans-serif;
    font-weight: 400;
    color: #fff;
    border: none;
    width: 200px !important;
    height: 70px !important;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    margin: 1vh 0;
}

.buttonDisabled{
    opacity: 0.7 !important;
    cursor:default !important;
}

.buttonDisabled:hover{
    background-image: url(../../assets/img/flyout/flyoutv6-p-500.png) !important;
}

.button:hover{
    background-image: url(../../assets/img/flyout/glow2-p-500.png);
}

@media only screen and (max-width: 1279px){
    .button{
        font-size: 0.8rem;
        width: 160px !important;
        height: 55px !important;
    }
}

@media only screen and (max-width: 900px){
    .button{
        font-size: 0.8rem;
        width: 120px !important;
        height: 40px !important;
    }
}

@media only screen and (max-width: 720px){
    .button{
        font-size: 0.5rem;
        width: 100px !important;
        height: 30px !important;
    }
}



@media only screen and (max-width: 480px){
    .button{
        font-size: 0.8rem;
        width: 150px !important;
        height: 50px !important;
    }
}

@media only screen and (max-width: 480px) and (orientation: landscape) {
    .button{
        font-size: 0.8rem;
    }
}



